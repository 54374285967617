<template>
  <div id="siteContainer">
    <div id="mainHeading">
      <div class="header">
        <h1><router-link to="/nexus-hq">Nexus HQ</router-link></h1>
        <breadcrumbs></breadcrumbs>
      </div>
    </div>

    <router-view id="mainColumn1"></router-view>

    <div id="mainColumn2">
      <div id="leftnavContent">
        <community-menu></community-menu>
      </div>
    </div>
    <div class="clear"></div>
  </div>
</template>

<script>
import CommunityMenu from "@/components/CommunityMenu.vue";

export default {
  data: function () {
    return {};
  },
  components: {
    CommunityMenu,
  },
};
</script>

<style lang="scss">
@import '@/views/community/nexus-hq/nexus-hq.scss';
</style>